<template>
   <wan-shan></wan-shan>
</template>

<script>
    import WanShan from "./base/WanShan";
    export default {
        name: "Wan",
        components:{
            WanShan
        }
    }
</script>

<style scoped>

</style>
<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}">
        <div class="small-box">
            <!-- <p class="text">东坡进销存连锁版</p> -->
            <div class="nei_box">
                <div class="nei_boxs">
                    <p class="zi_liao">完善企业资料</p>
                    <p class="zi_liaos">请如实填写资料，方便我们为您提供更完善的服务</p>
                    <div class="rights">
                        <div class="input_box">
                            <input type="text" v-model="form.c_name" onfocus="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #197cff';" onblur="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #eee';" placeholder="请输入">
                            <div class="imgs"> 公司名称</div>
                          </div>
                        <!--下拉框-->
                        <div class="select_box">
                            <el-select v-model="form.industry_id" placeholder="请选择">
                                <el-option
                                    v-for="item in industry"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <div class="imgs">所属行业</div>
                        </div>
                        <!--下拉框-->
                        <div class="select_box">
                            <el-select v-model="form.c_scale" placeholder="请选择">
                                <el-option
                                        v-for="item in scale"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <div class="imgs">公司规模</div>
                        </div>

                        <!--联系人姓名-->
                        <div class="input_box">
                            <input type="text" v-model="form.c_contacts" onfocus="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #197cff';" onblur="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #eee';" placeholder="请输入">
                            <div class="imgs"> 联系人姓名</div>
                        </div>
                        <!--邮箱地址-->
                        <div class="input_box">
                            <input type="text" v-model="form.c_email" onfocus="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #197cff';" onblur="if(this.value=='从这里搜索(^_^)'){this.value=''};this.style.backgroundColor='#fff';this.style.border='1px solid #eee';" placeholder="请输入">
                            <div class="imgs"> 邮箱地址</div>
                        </div>
                        <!--开始使用-->
                        <div class="button_box" @click="save">
                            开始使用
                        </div>
                        <!--下面文字-->
                        <div class="box_fu">
                            服务热线 0769-0000 0000
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <alikey></alikey>
    </div>
</template>

<script>
    import {scale,industry,steptwo} from "../../common/js/api";
    import Alikey from '../../../components/common/alikey.vue';
    export default {
        name: "WanShan",
        components:{
            Alikey
        },
        data() {
            return {
                industry: [],
                scale: [],
                value: '',
                user:"",
                uid:0,
                form:{
                    c_email:"",   //邮箱
                    c_name:"",   //公司名称
                    c_password:"", //密码
                    c_tel:"",   //手机号
                    industry_id:"", //所属行业
                    c_scale:"",  //规模
                    c_contacts:"",//联系人
                    c_time:"",
                },
                flag_two:true,
                lock:true
            }
        },
        async created(){
            industry().then((res)=>{
                this.industry = res.data;
            });
            scale().then((res)=>{
                this.scale = res.data.scale;
            });
            this.uid = this.$route.query.id;

        },
        methods: {
            //邮箱验证
            fnva(){
                var reg=/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                var  email= this.form.c_email
                    if(!reg.test(email)){
                        this.$message("输入的电子邮箱不符合校验规则，请重新输入!");
                        this.flag_two=true
                    }else if(reg.test(email)) {
                        this.flag_two=false
                    }
            },
            //完善
            async wan_shan(){
                if(!this.lock){
                    return;
                }
                this.lock = false;
                steptwo({
                //    **0day** 此处有任意用户修改漏洞
                    id:this.uid,
                    company:this.form.c_name,
                    industry:this.form.industry_id,
                    scale:this.form.c_scale,
                    username:this.form.c_contacts,
                    email:this.form.c_email
                }).then(res => {
                    if(res.code==200){
                        this.$message({
                            type: "success",
                            message: "资料完善成功，请选择角色!",
                        });
                        // 跳转选择角色
                        this.$router.push({
                            path:"/setRole?id="+this.uid
                        })
                    }else {
                        this.$message({
                            type: "error",
                            message:res.msg,
                        });
                    }

                    this.lock = true;

                }). catch(err => {
                    this.$message({
                        type: "error",
                        message:'网络不顺畅，请稍候再试',
                    });
                    this.lock = true;
                });
            },
            save(){
                // 验证邮箱
                this.fnva()
                if(this.flag_two==false){
                   this.wan_shan()
               } else {
                   this.fnva()
               }
            }

        }
    }
</script>

<style scoped lang="less">
    .box_fu{
        font-size: 15px;
        width: 400px;
        margin-top: 20px;
        text-align: center;
        color: #006eff;
    }
    .button_box{
        margin-top: 20px;
        width: 400px;
        height: 50px;
        background-color: #006eff;
        font-size: 15px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
    }
    /deep/.select_box{
        font-size: 15px;
        margin-top: 15px;
        position: relative;
        .imgs{
            position: absolute;
            top: 25px;
            left: 20px;
        }
        .el-input__inner{
            box-sizing: border-box;
            padding-left: 100px;
            width: 400px;
            height: 60px;

        }
    }
    input::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #aab2bd;
        /* placeholder字体大小  */
        font-size: 13px;
        /* placeholder位置  */
        /*text-align: right;*/
    }


   .container{
       width: 100%;
       height: 940px;
       background-color:#f5f9ff;
       .small-box{
           padding-top: 30px;
           width: 1200px;
           margin: 0 auto;
           .nei_box{
               width: 600px;
               height:700px;
               background-color: #fff;
               margin: 50px auto;
               overflow: hidden;
               .nei_boxs{
                   width: 400px;
                   margin: 50px auto;
                   .rights{
                       margin-top: 15px;
                       /*display: flex;*/
                       .input_box{
                           margin-top: 15px;
                           position: relative;
                           input{
                               box-sizing: border-box;
                               padding-left: 100px;
                               width: 400px;
                               height: 60px;
                               margin-right: 10px;
                               /*background-color: #f2f2f2;*/
                               border: 1px solid #eee;
                           }
                           .imgs{
                               position: absolute;
                               font-size: 15px;
                               top: 23px;
                               left: 15px;
                               width: 100px;
                               height: 60px;
                           }
                       }

                   }




                   .zi_liao{
                       font-size: 35px;
                       font-weight: 600;
                       color:#006eff;
                   }
                   .zi_liaos{
                       margin-top: 15px;
                       font-size: 15px;
                       color:#999999;
                   }
               }
           }
            .text{
                color:#006eff;
                font-size: 20px;
            }
       }
   }

</style>